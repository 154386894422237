exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-arm-index-jsx": () => import("./../../../src/pages/arm/index.jsx" /* webpackChunkName: "component---src-pages-arm-index-jsx" */),
  "component---src-pages-back-index-jsx": () => import("./../../../src/pages/back/index.jsx" /* webpackChunkName: "component---src-pages-back-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-flow-index-jsx": () => import("./../../../src/pages/flow/index.jsx" /* webpackChunkName: "component---src-pages-flow-index-jsx" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-menu-index-jsx": () => import("./../../../src/pages/menu/index.jsx" /* webpackChunkName: "component---src-pages-menu-index-jsx" */),
  "component---src-pages-neck-index-jsx": () => import("./../../../src/pages/neck/index.jsx" /* webpackChunkName: "component---src-pages-neck-index-jsx" */),
  "component---src-pages-shoulder-index-jsx": () => import("./../../../src/pages/shoulder/index.jsx" /* webpackChunkName: "component---src-pages-shoulder-index-jsx" */),
  "component---src-pages-staff-index-jsx": () => import("./../../../src/pages/staff/index.jsx" /* webpackChunkName: "component---src-pages-staff-index-jsx" */),
  "component---src-pages-waist-index-jsx": () => import("./../../../src/pages/waist/index.jsx" /* webpackChunkName: "component---src-pages-waist-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

